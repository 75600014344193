.App {
  text-align: center;
  width:98%;
  user-select: none; /* Disables text selection */
  -webkit-user-select: none; /* For Safari and Chrome */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer and Edge */  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.answercontainer {
	float:left;
	padding:10px;
	background:#e0e0e0;
	width:100%;
	font-size:18px;
	margin:10px;
	cursor:pointer;
  padding:4px;
}

.waybutton {
	position:relative;
	padding:5px;
	font-size: 18px;
	border-radius:5px;
	text-transform: uppercase;
}

#ottxt{
  font-size:18px;
  font-weight:bold;
}

.okbtn {
  margin-top:10px;
  font-size:30px;
  border-radius:10px;
  padding-left:8px;
  padding-right:8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
  cursor:pointer;
  color:black;
}

.answer {
	float:left;
	width:90%;
	background:white;
	padding:10px;
}

.answerbutton {
	float:left;
	width:8%;
	text-align:center;
	background:white;
	margin-right:10px;
	padding:10px;
}

.paticka {
  position:fixed;
  z-index: 50;
  width:100%;
  border:1px dashed black;
  height:35px;
  padding:2px;
  bottom:0px;
  font-size:16px;
  color:white;
  background:gray;
  text-align:left;

}

/* SWIPE */
.swipe {
  background:gray;
  padding:4px;
  border-radius: 5px;
  position:fixed;
  width:200px; 
  margin: 5% auto;
  left:0;
  right:0;
  box-shadow: 5px 10px #282c34;
  display:none;
  z-index: 100;
  opacity: 0.8;
}

.path {
  content: "";
  width: 20px;
  height: 20px;
  position: relative;
  background: rgba(149, 147, 241, 0.5);
  border-radius: 40px;
  top: 28px;
  left: 78px;
  animation: expand 2s infinite;
  margin: 0;
  padding: 0;
}

@keyframes expand {
  0% {
    width: 20px;
  }
  50% {
    width: 78px;
    left: 20px;
  }
  100% {
    width: 20px;
  }
}

.pokus {
  background:black;
  background-image: url('./asets/hand.png');
  height:50px;
  width:50px;
}

.hand-icon {
  position: relative;
  /* background:black; */
  /*background-image: url('https://i.postimg.cc/8556gm60/hand.png');*/
  background-image: url('./asets/hand.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 100px;
  animation: spin 2s infinite;
  transform-origin: 52% 62%;
}

@keyframes spin {
  0% {
    transform: rotate(30deg);
    margin-left: 20px;
  }
  50% {
    transform: rotate(-15deg);
    margin-left: -20px;
  }
  100% {
    transform: rotate(0deg);
    margin-left: 20px;
  }
}

/* SWIPE END */